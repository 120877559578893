import { HelpButton } from "@components/index";

const DrawerRow = ({ title, value, helpTitle, helpDescription }) => {
  return (
    <div className="drawerRow">
      <div>
      <b>{title}</b>
      {
        helpTitle &&
        <HelpButton
          helpContent={[
            {
              id: 0,
              title: helpTitle,
              description: helpDescription,
            },
          ]}
          symbol="!"
          className="alert"
        />
      }
      </div>
      <span>{value ? value : "Not Reported"}</span>
    </div>
  );
};

export default DrawerRow;
